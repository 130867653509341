import React from 'react';
import logo from 'assets/logo.png';
// import discord from 'assets/discord.png';
// import github from 'assets/github.png';
// import twitter from 'assets/twitter.png';
import './App.css';

function App() {
  const composeEmail = () => {
    const recipient = '2140tech1@gmail.com';
    const subject = encodeURIComponent('');
    const body = encodeURIComponent('');
    const url = 'https://mail.google.com/mail/?view=cm&to=' + recipient + '&su=' + subject + '&body=' + body;
    window.open(url);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <span className="App-title">2140</span>
      </header>
      <span className="App-introduce">Build the decentralized future</span>
      <button className="Contact-us" onClick={composeEmail}>
        Contact us
      </button>
      <span className="App-describe">
        {`2140 labs gathers the brightest minds to create infrastructure and tools for the decentralized future for
        everyone. Let's build without boundaries together.`}
      </span>
      {/* <div className="App-community">
        <div className="App-community-item">
          <img src={twitter} className="App-community-icon" alt="twitter" />
          <span className="App-community-name">Twitter</span>
        </div>
        <div className="App-community-item">
          <img src={discord} className="App-community-icon" alt="discord" />
          <span className="App-community-name">Discord</span>
        </div>
        <div className="App-community-item">
          <img src={github} className="App-community-icon" alt="github" />
          <span className="App-community-name">Github</span>
        </div>
      </div> */}
    </div>
  );
}

export default App;
